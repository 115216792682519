<template>
  <div>
    <div class="filterBox boxFrame mt-6 px-6 d-flex flex-column">
      <div class="d-flex flex-row header" @click="showFilters = !showFilters">
        <div class="text-h5 font-weight-medium"><v-icon class="mb-1 mr-1" color="primary">mdi-magnify</v-icon>Filters</div>
        <v-icon class="mt-1 ml-auto mr-2" :color="!showFilters ? 'primary' : ''">{{ showFilters ? "mdi-chevron-up" : "mdi-chevron-double-down" }}</v-icon>
      </div>

      <div class="d-flex flex-row">
        <div class="d-flex flex-row mt-1 mb-4 rounded flex-grow-1 mr-2" :class="showFilters ? 'white' : ''">
          <div style="width: 200px" class="ml-6 mt-2">
            <v-radio-group v-model="gene_list_type">
              <v-radio v-if="showFilters || gene_list_type == 'MANUAL_LIST'" label="Manual Gene List" value="MANUAL_LIST"></v-radio>
              <v-radio v-if="showFilters || gene_list_type == 'GENES_WITH_SCORES'" :class="showFilters ? 'mt-15' : ''" label="All Genes with Scores" value="GENES_WITH_SCORES">
              </v-radio>
            </v-radio-group>
          </div>
          <div class="d-flex flex-column flex-grow-1">
            <v-card v-show="showFilters || gene_list_type == 'MANUAL_LIST'" :disabled="gene_list_type != 'MANUAL_LIST'" color="transparent" flat class="align-start">
              <div class="flex pt-2 pr-6">
                <div v-show="showFilters" class="pt-3 pb-2 flex-grow-1">
                  <!-- <div class="text-h6 font-weight-medium "><v-icon class="mb-1 mr-1" color="primary">mdi-select-compare</v-icon>Gene List</div> -->
                  <FilterInput :showInputLabel="true" type="GENE" title="" :items="null" hint="" @inputType="onGeneSelected" v-model="selectedGeneList"></FilterInput>
                  <div class="d-flex flex-row">
                    <!-- <div class="caption grey--text">Hint: Multiple genes separated by comma</div> -->
                    <div v-if="err" class="ml-auto red--text caption">Choose at least one gene</div>
                  </div>
                </div>
                <div class="pb-4">
                  <div v-if="cleanedSelectedGenes.length > 0" class="d-flex flex-row flex-wrap" :class="!showFilters && gene_list_type == 'MANUAL_LIST' ? 'mt-1' : ''">
                    <div class="primary--text font-weight-medium mt-2 mr-1">{{ selectedGenesType }}</div>
                    <div class="mt-2 caption grey--text" v-if="cleanedSelectedGenes.length >= 10">({{ cleanedSelectedGenes.length }} genes)</div>
                    <v-chip v-for="(gene, idx) in cleanedSelectedGenes.slice(0, 10)" :key="gene + idx" class="mx-1 mt-2" :close="showFilters" color="primary lighten-1" rounded="0" label small outlined @click:close="removeGene(gene)">
                      <span>{{ gene }}</span>
                    </v-chip>
                    <v-menu v-if="cleanedSelectedGenes.length > 10" left v-model="menu" open-on-hover offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip v-on="on" v-bind="attrs" class="mx-1 px-1 mt-2" color="primary lighten-1" rounded="0" label small outlined>
                          <v-icon small>mdi-dots-horizontal</v-icon>
                        </v-chip>

                        <!-- <span v-on="on" v-bind="attrs" class="primary--text caption mt-2">more...</span> -->
                      </template>

                      <v-card width="800px" max-height="500px">
                        <v-card-text>
                          <v-chip v-for="(gene, idx) in cleanedSelectedGenes.slice(10)" :key="gene + idx" class="mx-1 mt-2" :close="showFilters" color="primary lighten-1" rounded="0" label small outlined @click:close="removeGene(gene)">
                            <span>{{ gene }}</span>
                          </v-chip>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </div>
              </div>
            </v-card>
            <Help  v-if="showFilters || gene_list_type == 'GENES_WITH_SCORES'" class="ml-n2 mt-auto mb-7"  section="UI" lookupKey="ALL_GENES_WITH_SCORES"> </Help>

            <!-- <div v-if="showFilters || gene_list_type == 'GENES_WITH_SCORES'" class="caption mt-auto mb-6 grey--text " :class="gene_list_type == 'GENES_WITH_SCORES' ? ' text--darken-2' : ' ' ">All genes with specific classification related to dosage sensitivity (i.e. genes haploinsufficy, triploinsufficy, and pLI scores)</div> -->
          </div>
        </div>

        <div v-show="showFilters" class="ml-6 pa-4 white rounded mt-1 justify-end" style="width: 300px">
          <div class="text-h6 font-weight-medium"><v-icon class="mb-1 mr-1" color="primary">mdi-select-compare</v-icon>Tests</div>
          <div class="ml-6">
            <v-checkbox dense disabled label="Coding Burden" input-value="true" hide-details></v-checkbox>
            <v-tooltip top max-width="500" :open-delay="300">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-checkbox dense v-model="localNonCodingBurdenTest" label="Non-Coding Burden" hide-details></v-checkbox>
                </span>
                <!-- <v-icon v-bind="attrs" v-on="on">mdi-cog</v-icon> -->
              </template>
              <span>Non-Coding Burden Test requires additional computing. Expect a slight increase in analysis duration</span>
            </v-tooltip>
          </div>
        </div>
        <div v-if="!showFilters" class="d-flex flex-row mr-10 justify-end">
          <div class="text-h6 font-weight-medium mt-n1 mr-4"><v-icon class="mb-1 mr-1" color="primary">mdi-select-compare</v-icon>Tests</div>
          <v-chip class="mx-1" :close="false" color="primary lighten-1" rounded="0" label small outlined> <span>Coding Burden</span> </v-chip>
          <v-chip v-if="localNonCodingBurdenTest" class="mx-1" :close="false" color="primary lighten-1" small rounded="0" label outlined> <span>Non-Coding Burden</span> </v-chip>
        </div>
      </div>

      <div class="text-h6 font-weight-medium mb-1 mt-n2"><v-icon class="mb-1 mr-1" color="primary">mdi-filter-outline</v-icon>Variants</div>
      <DefaultAnalysisFilters
        v-if="datasets.SV.info && datasets.SNV.info && datasets.TR.info"
        :selectedDatabases="[datasets.SV.info, datasets.SNV.info, datasets.TR.info]"
        :cohortId="cohortId"
        filterGroupName="MANUAL_COHORT_ANALYSIS_FILTER"
        @onFilterSet="onFilterSet"
        searchButtonTitle="Search"
        searchButtonIcon="mdi-magnify"
        :showFilters="showFilters"
        :saveFilters="true"
        saveKeyName="manualCohortAnalysis"
        :showVariantLabelFilter="false"
        :reversibleCategorical="true"
        :defaultFilters="defaultFilters"
        :extraDescription="extraFilterDescription"
      >
      </DefaultAnalysisFilters>
    </div>
    <div v-if="showResult" class="filterBox boxFrame mt-6 px-6">
      <div class="d-flex flex-row">
        <div class="text-h5 font-weight-medium"><v-icon class="mb-1 mr-1" color="primary">mdi-format-list-group</v-icon>Search Result</div>
      </div>
      <ViewCohortAnalysisGeneResult
        ref="ViewCohortAnalysisGeneResult"
        :datasets="datasets"
        :cohortId="cohortId"
        :projectId="projectId"
        :selectedGenes="cleanedSelectedGenes"
        :filters="filters"
        :filterLabels="filterLabels"
        :filterLogic="filterLogic"
        :nonCodingBurdenTest="nonCodingBurdenTest"
        :gene_list_type="gene_list_type"
      ></ViewCohortAnalysisGeneResult>
    </div>
  </div>
</template>
<script>
// import { getCohortAnalysis , getProjectGeneLabel, setProjectGeneLabel,updateProjectGeneLabel} from "@/api/user/cohort.js";
import Help from "@/components/Common/Help.vue";

import DefaultAnalysisFilters from "@/components/defaultAnalysis/DefaultAnalysisFilters.vue";
import FilterInput from "@/components/defaultAnalysis/filters/FilterInput.vue";
import ViewCohortAnalysisGeneResult from "@/components/CohorAnalysis/ViewCohortAnalysisGeneResult.vue";
import { getDatasetsByProject } from "@/api/dataset.js";
import { getDatasetFields } from "@/api/dataset";
// import { getFieldGroups } from "@/api/settings";
// import { getVariantLabels } from "@/api/settings";
export default {
  name: "CohortAnalysis",
  components: { DefaultAnalysisFilters, FilterInput, ViewCohortAnalysisGeneResult, Help },
  data: () => ({
    nonCodingBurdenTest: false,
    localNonCodingBurdenTest: false,
    showFilters: true,
    showResult: false,
    cohortId: null,
    projectId: null,
    err: false,
    menu: false,
    gene_list_type: "MANUAL_LIST",
    selectedGeneList: [],
    selectedGenesType: null,
    filters: {
      SV: {
        inFilters: [],
        exactFilters: [],
        rangeFilters: [],
        notFilters: [],
      },
      SNV: {
        inFilters: [],
        exactFilters: [],
        rangeFilters: [],
        notFilters: [],
      },
      TR: {
        inFilters: [],
        exactFilters: [],
        rangeFilters: [],
        notFilters: [],
      },
    },
    filterLabels: [],
    filterLogic: {},
    datasets: {
      SNV: {
        info: null,
        result: [],
        fields: [],
      },
      SV: {
        info: null,
        result: [],
        fields: [],
      },
      TR: {
        info: null,
        result: [],
        fields: [],
      },
    },
    // filtersByTitle: [],

    // fieldGroups: [],

    defaultFilters: {
      FILTER: ["PASS"],
      QUAL: [0.4, null],
      DISEASE_STATUS: ["affected"],
      CLINVAR: [
        "",
        "affects",
        "association",
        "confers_sensitivity",
        "conflicting_interpretations_of_pathogenicity",
        "likely_pathogenic",
        "likely_risk_allele",
        "not_provided",
        "other",
        "pathogenic",
        "pathogenic/established_risk_allele",
        "uncertain_risk_allele",
        "uncertain_significance",
      ],
    },
    extraFilterDescription: {
      QUAL: {
        replaceOrigDescription: false,
        desc: "Recommended value for QUAL is greater than or equal to 0.4",
      },
      FILTER: {
        replaceOrigDescription: false,
        desc: 'Recommended value for FILTER is "PASS"',
      },
      DISEASE_STATUS: {
        replaceOrigDescription: false,
        desc: 'Recommended value for DISEASE_STATUS is "affected"',
      },
      CLINVAR: {
        replaceOrigDescription: false,
        desc: "Recommended value for CLINVAR is one of '' ,affects ,association ,confers_sensitivity ,conflicting_interpretations_of_pathogenicity ,likely_pathogenic ,likely_risk_allele ,not_provided ,other ,pathogenic ,pathogenic/established_risk_allele ,uncertain_risk_allele ,uncertain_significance",
      },
    },
  }),
  watch: {
    cleanedSelectedGenes() {
      this.err = false;
    },
  },
  computed: {
    cleanedSelectedGenes() {
      if (this.selectedGeneList.length > 0) {
        return this.selectedGeneList.filter((x) => x !== "");
      } else {
        return [];
      }
    },
  },
  created() {
    this.cohortId = Number(this.$route.params.cohortid);
    this.projectId = Number(this.$route.params.id);
    const _this = this;
    // getProjectGeneLabel((res) => {
    //   _this.geneLabels = res;
    // });
    // getVariantLabels((res) => {
    //   _this.variantLabels = res;
    // });
    // getFieldGroups(function (res) {
    //   _this.fieldGroups = res;
    // });
    getDatasetsByProject(this.$route.params.id, (res) => {
      Object.keys(this.datasets).forEach((dsType) => {
        _this.datasets[dsType].info = res.find((item) => item.datasetType.name == dsType);
        getDatasetFields(_this.datasets[dsType].info.id, (rs) => {
          _this.datasets[dsType].fields = rs;
        });
      });
    });

    const pref = this.$store.state.UserPreferences;
    if ((pref && pref.manualCohortAnalysis && (pref.manualCohortAnalysis[this.projectId] || {}).selectedGeneList) || null) {
      this.selectedGeneList = pref.manualCohortAnalysis[this.projectId].selectedGeneList;
      this.selectedGenesType = pref.manualCohortAnalysis[this.projectId].selectedGenesType;
    }
    // if ((pref && pref.manualCohortAnalysis && (pref.manualCohortAnalysis[this.projectId] || {}).filtersLabels) || null) {
    //   this.filtersByTitle = pref.manualCohortAnalysis[this.projectId].filtersLabels;
    // }
  },
  methods: {
    saveInfoInfoStore() {
      let prf = this.$store.state.UserPreferences;
      if (!prf) {
        prf = {};
      }
      if (!prf.manualCohortAnalysis) {
        prf.manualCohortAnalysis = {};
      }
      if (!prf.manualCohortAnalysis[this.projectId]) {
        prf.manualCohortAnalysis[this.projectId] = {};
      }
      prf.manualCohortAnalysis[this.projectId].selectedGeneList = this.selectedGeneList;
      prf.manualCohortAnalysis[this.projectId].selectedGenesType = this.selectedGenesType;
      this.$store.commit("userPreferences", prf);
    },

    removeGene(g) {
      this.selectedGeneList.splice(this.selectedGeneList.indexOf(g), 1);
    },
    onFilterSet(filtersPerDs, variantLabelsPerDs, selectedPropertiesLabels, collabLabelsPerDs, filterLogic) {
      // console.log('selectedPropertiesLabels',selectedPropertiesLabels)
      // console.log('dim',dim),
      // console.log('filters',filtersPerDs)
      // console.log('doom',doom)
      if (this.cleanedSelectedGenes.length == 0 && this.gene_list_type == 'MANUAL_LIST') {
        this.err = true;
      } else {
        Object.keys(this.filters).forEach((dsType) => {
          this.filters[dsType].exactFilters = filtersPerDs[dsType].exactFilters;
          this.filters[dsType].inFilters = filtersPerDs[dsType].inFilters;
          this.filters[dsType].notFilters = filtersPerDs[dsType].notFilters;
          this.filters[dsType].rangeFilters = [...filtersPerDs[dsType].rangeFromfilters, ...filtersPerDs[dsType].rangeTofilters, ...filtersPerDs[dsType].rangeBetweenfilters];
        });

        this.filterLabels = [...selectedPropertiesLabels];
        this.filterLogic = Object.assign({}, filterLogic);
        // console.log(variantLabelsPerDs)
        // this.filtersByTitle = selectedPropertiesLabels;
        this.saveInfoInfoStore();
        this.showFilters = false;
        this.showResult = true;
        this.nonCodingBurdenTest = this.localNonCodingBurdenTest;
        this.$nextTick(() => {
          this.$refs.ViewCohortAnalysisGeneResult.update();
        });
      }
    },
    onGeneSelected(v) {
      this.selectedGenesType = v;
    },
  },
};
</script>
<style scoped  lang="scss">
.filterBox {
  background: #9aacad13 !important;
}
.header {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-radius: 6px;
}
.header:hover {
  cursor: pointer;
  background-color: #55555508 !important;
}
</style>